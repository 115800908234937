import React, { useState } from "react";
import { t } from "i18next";
import styles from "./resetpasswordcrm.module.scss";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { SuccessCard } from "../../../../shared/success-card";
import Login from "../../../../shared/login-widget/index";
import LoginWidget from "../../../../shared/login-widget/index";
import { useNavigate } from "react-router-dom";

export const SuccessCrm = () => {
  const isMobileView: boolean = useScreenSize().device === "mobile";
  const navigate = useNavigate();
  const [isPanelClosed, setIsPanelClosed] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const isCRM = sessionStorage.getItem("isROPLoggedIn");
  const isCRMAuthenticated = isCRM === 'false';
  const handlePanelShow = (): void => {
    
    setIsPanelClosed(!isPanelClosed);
  };
  const handleSignInPopupShow = (): void => {
    
    setShowSignIn(!showSignIn);
  };
  const handleSignInPopupClose = (callback?: Function): void => {
    setShowSignIn(false);
    navigate(window.location.origin);
  };
  return (
    <>
      {isMobileView && (
        <div className={styles.conitnueMobileSecondContainer}>
          <span className={styles.primaryText}>
            {t('crm_reset_success_heading')}
          </span>
          <span className={styles.secondarytext}>
            {t('crm_reset_succes_heading_secondary')}
          </span>
          <div className={styles.mobileSuccessContainer}>
            <SuccessCard
              iconName="user-icon"
              text={t("label_signin_header")}
              redirection="sidebar"
              handlePanelShow={handlePanelShow}
            />
            <SuccessCard
              iconName="home-icon"
              text={t('label_back_to_home')}
              redirection="route"
              handlePanelShow={handlePanelShow}
            />
          </div>
          {isPanelClosed && !isCRMAuthenticated && (
            <LoginWidget
              onHidePanel={handleSignInPopupClose}
              loginName={() => {}}
              handlePanelShow={handleSignInPopupShow}
              isTabbed={true}
              tabbedName="CRM"
            />
          )}
        </div>
      )}
      {!isMobileView && (
        <>
          <div className={styles.continueContent}>
            <span className={styles.primaryText}>
              {t('crm_reset_success_heading')}
            </span>
            <span className={styles.secondarytext}>
              {t('crm_reset_succes_heading_secondary')}
            </span>
            <div className={styles.container}>
              <SuccessCard
                iconName="user-icon"
                text={t("label_signin_header")}                redirection="sidebar"
                handlePanelShow={handlePanelShow}
              />
              <SuccessCard
                iconName="home-icon"
                text={t('label_back_to_home')}
                redirection="route"
                handlePanelShow={handlePanelShow}
              />
            </div>
          </div>
          {isPanelClosed && !isCRMAuthenticated && (
            <LoginWidget
              onHidePanel={handleSignInPopupClose}
              loginName={() => {}}
              handlePanelShow={handleSignInPopupShow}
              isTabbed={true}
              tabbedName="CRM"
            />
          )}
        </>
      )}
    </>
  );
};
