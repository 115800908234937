import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import styles from "./resetunlock.module.scss";
import { t } from "i18next";

interface ResetUnlockParams {
  imageText?: string;
  descriptionText1?: string;
  descriptionText2?: string;
  children?: any;
  banner?:any;
  imageTextClassName?:any;
  descriptionTextClassName?:any;
  titleContainer?:any;
  secondaryContainer?: any;
}
export const ResetUnlockBanner: React.FC<ResetUnlockParams> = ({
  imageText,
  children,
  descriptionText1,
  descriptionText2,
  banner,
  imageTextClassName,
  descriptionTextClassName,
  titleContainer,
  secondaryContainer
}) => {
  const navigate = useNavigate();

  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  return (
    <>
      {!isDesktopView && (
        <div className={styles.mobileResetContainer}>
          <div className={banner ? banner : `${styles.mobileFirstContainer}`}>
            <div className={ titleContainer ? titleContainer :`${styles.titleContainer}`}  >
              <span className={imageTextClassName ? imageTextClassName : `${styles.mobileFirstContainerText}`}>
                {imageText}
              </span>
              {descriptionText1 && (
                <span  className={ descriptionTextClassName ? descriptionTextClassName : `${styles.mobileDescriptionText}`}>
                  {descriptionText1}
                </span>
              )}
              {descriptionText2 && (
                <span className={styles.mobileDescriptionText} >
                  {descriptionText2}
                </span>
              )}
            </div>
          </div>
          <>
            <div className={styles.mobileSecondContainer}>
              <div className={styles.mobilePrimaryContentContainer}>
                {children}
              </div>
            </div>
          </>
        </div>
      )}
      {isDesktopView && (
        <div className={styles.resetContainer}>
          <div className={banner ? banner :`${styles.firstContainer}`}>
            <div className={ imageTextClassName ? imageTextClassName :  `${styles.titleContainer}`}>
              <span className={styles.firstContainerText}>{imageText}</span>
              {descriptionText1 && (
                <div className={styles.descriptionText}>{descriptionText1}</div>
              )}
              {descriptionText2 && (
                <div className={styles.descriptionText}>{descriptionText2}</div>
              )}
            </div>
          </div>
          <>
            <div className={ secondaryContainer ? secondaryContainer : `${styles.secondContainer}`}>
              <div className={styles.primaryContentContainer}>
                <div className={styles.secondaryContainer}>
                  <div className={styles.content}>{children}</div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};
