import React, { useState } from "react";
import { t } from "i18next";
import styles from "./resetunlock.module.scss";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { SuccessCard } from "../../../../shared/success-card";
import Login from "../../../../shared/login-widget/index";
import isAuthenticate from "../../../../utils/hook/isAuthenticate";

export const Success = () => {
  const isMobileView: boolean = useScreenSize().device === "mobile";
  const [isPanelClosed, setIsPanelClosed] = useState(false);
  const isAuthenticated = isAuthenticate();

  const handlePanelClose = (): void => {
    setIsPanelClosed(false);
  };
  const handlePanelShow = (): void => {
    setIsPanelClosed(true);
  };

  const getUserName = () => {
    return "test";
  };
  return (
    <>
      {isMobileView && (
        <>
          <div className={styles.conitnueMobileSecondContainer}>
            <span className={styles.primaryText}>
              {t('reset_success_primary')}
            </span>
            <span className={styles.secondarytext}>
              {t('reset_success_secondary')}
            </span>
            <div className={styles.mobileSuccessContainer}>
              <SuccessCard
                iconName="user-icon"
                text={t("label_signin_header")}                redirection="sidebar"
                handlePanelShow={handlePanelShow}
              />
              <SuccessCard
                iconName="home-icon"
                text={t('label_back_to_home')}
                redirection="route"
                handlePanelShow={handlePanelShow}
              />
            </div>
          </div>
          {isPanelClosed && !isAuthenticated && (
            <Login
              onHidePanel={handlePanelClose}
              loginName={getUserName}
              handlePanelShow={handlePanelShow}
            />
          )}
        </>
      )}
      {!isMobileView && (
        <>
          <div className={styles.continueContent}>
            <span className={styles.primaryText}>
              {t('reset_success_primary')}
            </span>
            <span className={styles.secondarytext}>
              {t('reset_success_secondary')}
            </span>
            <div className={styles.container}>
              <SuccessCard
                iconName="user-icon"
                text={t("label_signin_header")}                redirection="sidebar"
                handlePanelShow={handlePanelShow}
              />
              <SuccessCard
                iconName="home-icon"
                text={t('label_back_to_home')}
                redirection="route"
                handlePanelShow={handlePanelShow}
              />
            </div>
          </div>
          {isPanelClosed && !isAuthenticated && (
            <Login
              onHidePanel={handlePanelClose}
              loginName={getUserName}
              handlePanelShow={handlePanelShow}
            />
          )}
        </>
      )}
    </>
  );
};
