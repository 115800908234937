import React, { useEffect, useState } from "react";
import { ResetUnlockBanner } from "../../pages/reset-unlock/banner";
import { t } from "i18next";
import styles from "./resetpasswordcrm.module.scss";
import TGInput from "../../../../shared/tg-input";
import { set } from "date-fns";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import { SuccessCrm } from "./success";
import { CRMData } from "../../models/models";
import { useDispatch, useSelector } from "react-redux";
import { getCRMDataPending, resetCRMData } from "../../slice/resetCRMSlice";
import { RootState } from "../../slice/RootReducer";
import { Spinner } from "react-bootstrap";
import { MyProfile } from "../myprofile/rop-profile/profile-component";
export const ResetCrm = () => {
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [CRMData, setCRMData] = useState<CRMData>({
    email: "",
  });
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState<Partial<CRMData>>({});
  const CRMResponse = useSelector(
    (state: RootState) => state?.osciReducer?.crmReset
  );
  useEffect(() => {
    if (CRMResponse.resetCRMDetails.statusMessage == "Success") {
      setIsSuccess(true);
    } else {
      if (CRMResponse.error.Code == "400") {
        setErrors({ ...errors, email: t("crm_email_bad_request") });
        dispatch(resetCRMData());
      }
      setIsSuccess(false);
    }
  }, [dispatch, CRMResponse]);
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setCRMData({ ...CRMData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const getFieldLabel = (fieldName: keyof CRMData) => {
    const labels: { [key in keyof CRMData]: string } = {
      email: t("signin_email_required"),
    };
    return labels[fieldName];
  };
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newErrors: Partial<CRMData> = {};
    Object.entries(CRMData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof CRMData] = getFieldLabel(key as keyof CRMData);
      } else if (key === "email" && !isValidEmail(value)) {
        newErrors[key as keyof CRMData] = t("reset_invalid_message");
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      dispatch(
        getCRMDataPending({
          email: CRMData.email,
        })
      );
    }
  };
  return (
    <ResetUnlockBanner imageText={t("label_signin_reset_password")}>
      {!isSuccess && (
        <>
          <div className={styles.resetCRMContainer}>
            <div className={styles.secondaryCRMContainer}>
              <div className={styles.textCRMContainer}>
                {t("label_reset_text")}
              </div>
              <div className={styles.emailInputCOntainer}>
                <TGInput
                  label={t("label_reset_email")}
                  value={CRMData.email}
                  name="email"
                  onChange={handleChange}
                  isError={!!errors.email}
                  errorLabel={errors.email}
                  placeholder="example@email.com"
                  validation="language"
                />
              </div>
              <div
                className={
                  isDesktopView
                    ? styles.continueButton
                    : styles.continueButtonMobile
                }
              >
                <TGButtonVariants
                  label={
                    CRMResponse.isLoading ? "" : t("button_reset_continue")
                  }
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor="#684B9B"
                  padding="16px"
                  textColor="#FFFFFF"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  borderDisabled="0.791px solid #E3D4FE"
                  bgColorDisabled="#E3D4FE"
                  boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColorHover="#684b9b"
                  borderHover="0.791px solid #E3D4FE"
                  boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  height="50px"
                  width={isDesktopView ? "25%" : "100%"}
                  onClick={handleSubmit}
                >
                  {CRMResponse.isLoading ? (
                    <>
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                      <Spinner
                        animation="grow"
                        variant="light"
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "5px",
                        }}
                      />
                    </>
                  ) : null}
                </TGButtonVariants>
              </div>
            </div>
          </div>
        </>
      )}
      {/* If the condition got success, page has to be redirect to Success page */}
      {isSuccess && <SuccessCrm />}
    </ResetUnlockBanner>
  );
};
